import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { motion } from 'framer-motion'
import ReactScrollWheelHandler from 'react-scroll-wheel-handler'
import { Container, Row, Col } from 'react-bootstrap'

import SEO from '../components/SEO'
import Layout from '../layouts/Main'

export default ({ location, data }) => {
  const { edges: projects } = data.allMarkdownRemark
  const [active, setActive] = useState(0)

  const imageHeight = 400
  const contentHeight = 100
  const indicatorItemHeight = 20

  const DURATION = 2.5

  let posImage = imageHeight * active
  let posContent = contentHeight * active

  const imageVariants = {
    active: {
      y: '100%',
      transition: {
        duration: DURATION,
      },
    },
    inActive: {
      y: 0,
      transition: {
        duration: DURATION,
      },
    },
  }

  const contentVariants = {
    active: {
      y: '100%',
      transition: {
        duration: DURATION,
        type: 'spring',
        stiffness: 15,
      },
    },
    inActive: {
      y: 0,
      transition: {
        duration: DURATION,
        type: 'spring',
        stiffness: 15,
      },
    },
  }

  const wrapperVariants = {
    initial: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
      transition: {
        duration: DURATION,
        delay: 1,
        when: 'beforeChildren',
      },
    },
  }

  // slide top
  const slidePrev = () => {
    if (active === 0) {
      return false
    } else {
      setActive(active - 1)
    }
  }

  const slideNext = () => {
    if (active === projects.length - 1) {
      return false
    } else {
      setActive(active + 1)
    }
  }

  return (
    <Layout location={location}>
      <SEO
        title="Projets"
        description="Charles Abedie 🤟, UX/UI Designer & Creative Developer basé à Paris"
      />

      <div className="p-home">
        <motion.div
          initial="initial"
          animate="enter"
          variants={wrapperVariants}
        >
          {/* SMALL SCREEN VERSION*/}
          <div className="d-block d-xl-none">
            <h1 className="p-home__title">Projets</h1>
            <Container>
              {projects.map(({ node: project }, key) => (
                <section
                  key={key}
                  className="p-home__section"
                  data-scroll
                  style={{ textAlign: 'center', marginBottom: '3rem' }}
                >
                  <div key={key}>
                    <Link to={`/projets/${project.frontmatter.slug}`}>
                      <Img
                        style={{ height: '200px', marginBottom: 0 }}
                        fluid={{
                          ...project.frontmatter.cover2.childImageSharp.fluid,
                        }}
                      />
                    </Link>
                  </div>
                  <h1
                    style={{ marginTop: '1rem' }}
                    className="mrd-carousel__title mrd-title__primary"
                  >
                    {project.frontmatter.title}
                  </h1>
                  <h2 className="mrd-carousel__subtitle">
                    {project.frontmatter.category}
                  </h2>
                </section>
              ))}
            </Container>
          </div>
          {/* END MSMALL SCREEN VERSION */}

          {/* LARGE SCREEN AND XTRA LARGE SCREEN VERSION */}
          <div className="d-none d-xl-block">
            <ReactScrollWheelHandler
              upHandler={e => slidePrev()}
              downHandler={e => slideNext()}
            >
              <div className="mrd-carousel" data-section>
                <Container>
                  <Row className="justify-content-center">
                    <Col xs={12} xl={9}>
                      <div className="mrd-carousel__wrapper-image">
                        <motion.div
                          initial={{
                            y: 0,
                          }}
                          animate={{
                            y: -`${posImage}`,
                          }}
                          transition={{ duration: DURATION }}
                          className="mrd-carousel__container"
                        >
                          {projects.map(({ node: project }, key) => {
                            let { title: alt } = project.frontmatter.title
                            let image =
                              project.frontmatter.cover2.childImageSharp.fluid

                            return (
                              <Link
                                to={`projets/${project.frontmatter.slug}`}
                                className="image"
                                key={project.id}
                              >
                                <motion.div
                                  variants={imageVariants}
                                  animate={
                                    active === key ? 'active' : 'inActive'
                                  }
                                  className="image-overlay"
                                />

                                <div>
                                  <Img
                                    style={{ height: `${imageHeight}px` }}
                                    fluid={{ ...image, aspectRatio: 16 / 9 }}
                                    alt={`Cover ${alt}`}
                                  />
                                </div>
                              </Link>
                            )
                          })}
                        </motion.div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col xl={9}>
                      <div className="content">
                        <motion.div
                          animate={{
                            y: -`${posContent}`,
                          }}
                          transition={{ duration: DURATION }}
                          className="content-wrapper"
                        >
                          {projects.map(({ node: project }, key) => {
                            return (
                              <div key={key} className="content-item">
                                <motion.div
                                  variants={contentVariants}
                                  animate={
                                    active === key ? 'active' : 'inActive'
                                  }
                                  className="content-overlay"
                                />
                                <h1 className="mrd-carousel__title mrd-title__primary">
                                  {project.frontmatter.title}
                                </h1>
                                <h2 className="mrd-carousel__subtitle">
                                  {project.frontmatter.category}
                                </h2>
                              </div>
                            )
                          })}
                        </motion.div>
                      </div>
                    </Col>
                  </Row>
                </Container>
                <span className="scroll-indicator">Scroll</span>
              </div>
              <div
                className="mrd-carousel-indicator"
                data-section
                data-scroll-sticky
              >
                <div className="mrd-carousel-indicator__wrapper">
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1,
                    }}
                    transition={{
                      duration: DURATION,
                      delay: 0.5,
                    }}
                    // style={{ height: indicatorHeight + 'px' }}
                    className="mrd-carousel-indicator__bar"
                  >
                    {projects.map((p, k) => {
                      return (
                        <div
                          key={k}
                          aria-hidden="true"
                          onClick={() => setActive(k)}
                          style={{ height: indicatorItemHeight + 'px' }}
                          className={`mrd-carousel-indicator__item ${
                            k === active ? 'active' : ''
                          }`}
                        />
                      )
                    })}
                  </motion.div>
                </div>
              </div>
            </ReactScrollWheelHandler>
          </div>
        </motion.div>
      </div>
      {/* LARGE SCREEN AND XTRA LARGE SCREEN VERSION */}
    </Layout>
  )
}

export const pageQuery = graphql`
  query ListProjects {
    allMarkdownRemark(sort: { order: DESC, fields: frontmatter___year }) {
      edges {
        node {
          id
          frontmatter {
            title
            slug
            category
            color
            cover {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            cover2 {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
